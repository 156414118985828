import { useMutation } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { ErrorRes, SuccessRes } from "../../../utils/models/responseType"
import { endpoints } from "../utils/constants/endpoints"

// update favourites
export function useUpdateFavourites() {
  return useMutation<SuccessRes, ErrorRes, { id: number; key: string }>(
    async (req) => {
      const res = await $api.put(endpoints.FAVOURITE, {
        [req.key]: req.id,
      })
      return res.data
    },
    {
      onError: errorHandler,
    }
  )
}
