import { Button, Tooltip } from "antd"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { useTranslations } from "next-intl"
import Image from "next/image"
import Link from "next/link"
import React from "react"
import ReactGA from "react-ga4"

import ComplexHeart from "../../../pages/heart/components/complex/ComplexHeart"
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks"
import { userReducerActions } from "../../../store/reducers/userReducer"
import { gaActions, gaCategories } from "../../../utils/constants/ga"
import { RU, UZS } from "../../../utils/constants/locales"
import { rootPaths } from "../../../utils/constants/rootPaths"
import { viewTypes } from "../../../utils/constants/viewTypes"
import { isEmptyArr } from "../../../utils/helpers/isEmptyArr"
import { makeUrl } from "../../../utils/helpers/makeUrl"
import { NewResidentalComplexModel } from "../../../utils/models/residentialComplexModel"
import NonSSRWrapper from "../../non-ssr/NonSSRWrapper"
import CommonCardTop from "../top/CommonCardTop"

import styles from "../commonCard.module.scss"

import { useRouter } from "next/router"

import PhoneIcon from "../../../assets/icons/PhoneIcon"
import { layoutLang } from "../../../utils/language/layout"
import { calcUsdValue } from "../../../utils/helpers/calcUsdValue"
import Placements from "../../../pages/detailed-residential-complex/components/placements/Placements"

import badge1 from "../../../assets/images/Badge2.png"
import badge2 from "../../../assets/images/Badge1.png"

dayjs.extend(advancedFormat)

type Props = {
  viewType: viewTypes
  data: NewResidentalComplexModel
}

const ComplexCard: React.FC<Props> = ({ data, viewType }) => {
  const t = useTranslations("common.ComplexCard")
  const dispatch = useAppDispatch()
  const { locale } = useRouter()
  const { setCallBackModal } = userReducerActions
  const { currency, usdValue } = useAppSelector((state) => state.localeReducer)

  // price cont
  const priceCont = () => {
    const totalPrice = data?.price_permission ? data?.min_total_price : null

    // checking
    if (!totalPrice) {
      return (
        <div className={styles.price_cont}>
          <p>{layoutLang[String(locale)]["Договорная"]}</p>
        </div>
      )
    }

    return (
      <div className={styles.price_cont}>
        <p>
          {totalPrice
            ? locale === "uz"
              ? `${Math.ceil(
                  calcUsdValue(totalPrice, usdValue, currency)
                )?.toLocaleString(RU)} ${
                  currency === UZS ? "so'mdan" : "$ dan"
                }`
              : `от ${Math.ceil(
                  calcUsdValue(totalPrice, usdValue, currency)
                )?.toLocaleString(RU)} ${currency === UZS ? "сум" : "$"}`
            : layoutLang[String(locale)]["Договорная"]}
        </p>
      </div>
    )
  }

  // coll back
  const handleCallBack = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    dispatch(
      setCallBackModal({
        visible: true,
        type: "complex",
        id: data?.id,
      })
    )
    ReactGA.event({
      action: gaActions.CALL_ME,
      category: gaCategories.PHONE_NUMBER,
    })
  }

  return (
    <Link
      href={`/${data?.district?.city?.url}${
        rootPaths.RESIDENTIAL_COMPLEXES
      }/${makeUrl(data?.name || data?.name)}-${data?.id}`}
      legacyBehavior
    >
      <a className={`${styles.complex_card} ${styles[viewType]}`}>
        <CommonCardTop
          images={data?.image}
          classes={data?.class}
          viewType={viewType}
          is_top={data?.is_top}
          alt={`${layoutLang[String(locale)]["ЖК"]} "${
            data?.name || data?.name
          }"`}
        />
        <div className={styles.complex_card_bottom}>
          {(data?.bonuses || !isEmptyArr(data?.payment_methods)) && (
            <div className={styles.top}>
              <div className={styles.tags}>
                {data?.bonuses && (
                  <div className={styles.tag}>
                    <span>{data?.bonuses}</span>
                  </div>
                )}
                {data?.payment_methods?.map((item, index) => (
                  <div className={styles.tag} key={index}>
                    <span>
                      {locale === RU ? item?.name?.ru : item?.name?.ru}
                    </span>
                  </div>
                ))}
              </div>
              <div className={styles.like}>
                <NonSSRWrapper>
                  <ComplexHeart data={data} />
                </NonSSRWrapper>
              </div>
            </div>
          )}
          <div className={styles.name}>
            <h3>
              {layoutLang[String(locale)]["ЖК"]} "{data?.name}"
            </h3>
            {!data?.bonuses && isEmptyArr(data?.payment_methods) && (
              <div className={styles.like}>
                <NonSSRWrapper>
                  <ComplexHeart data={data} />
                </NonSSRWrapper>
              </div>
            )}
          </div>
          <p className={styles.address}>
            {locale === RU
              ? data?.district?.city?.name?.ru
              : data?.district?.city?.name?.uz}
            {", "}
            {locale === RU
              ? data?.district?.name?.ru
              : data?.district?.name?.uz}
          </p>
          <Placements type="complex" data={data?.nearest_places} />
          {priceCont()}
          <div className="flex items-center mb-4 gap-2">
            <p className={`${styles.builder} !mb-0 !mt-1`}>
              {data?.company?.brand_name
                ? data?.company?.brand_name
                : data?.builder?.name}
            </p>
            {data?.company?.badge === "trusted" ? (
              <Tooltip overlay={layoutLang[String(locale)]["ishonchli"]}>
                <Image width={16} height={16} src={badge2} alt="err" />
              </Tooltip>
            ) : data?.company?.badge === "approved" ? (
              <Tooltip overlay={layoutLang[String(locale)]["tasdiqlangan"]}>
                <Image width={16} height={16} src={badge1} alt="err" />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
          <div className={styles.buttons_cont}>
            <Button className={styles.call} onClick={handleCallBack}>
              <PhoneIcon />
              {layoutLang[String(locale)]["Перезвоните мне"]}
            </Button>
          </div>
        </div>
      </a>
    </Link>
  )
}

export default ComplexCard
